<template>
  <div>
    <el-form
      label-position="right"
      :model="ruleForm"
      ref="ruleForm"
      :rules="rules"
      class="ruleForm"
      label-width="60px"
      style="text-align:left"
    >
      <el-col :span="24">
        <el-form-item label="公司电话" label-width="110px" prop="stylePhone">
          <el-input v-model="ruleForm.stylePhone" style="width: 100%" placeholder="请输入公司电话"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="公司地址" label-width="110px" prop="styleAddress">
          <el-input v-model="ruleForm.styleAddress" placeholder="请输入公司地址"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="display: flex; align-items: center">
        <el-form-item label="登录页背景图" label-width="110px" prop="styleBackImg">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="successFile"
            :before-upload="beforeAvatarUpload"
            :data="uploadData"
            accept=".png, .jpg, jpeg, JPG, JPEG"
          >
            <img
              v-if="ruleForm.styleBackImg"
              :src="ruleForm.styleBackImg"
              style="width:300px;height:150px"
              class="avatar"
            />
            <el-button style="margin-left:20px" type="primary" plain size="small" v-else>上传</el-button>
            <span
              slot="tip"
              class="el-upload__tip"
              style="    display: inline-block;color: chocolate;margin: 0 10px;"
            >*340px*150px</span>
          </el-upload>
        </el-form-item>
      </el-col>
      <el-col :span="24" style="display: flex; align-items: center">
        <el-form-item label="企业Logo" label-width="110px" prop="styleLogo">
          <el-upload
            class="avatar-uploader"
            :action="action"
            :show-file-list="false"
            :on-success="successFileOne"
            accept=".png, .jpg, jpeg, JPG, JPEG"
            :before-upload="beforeAvatarUpload"
          >
            <img
              v-if="ruleForm.styleLogo"
              :src="ruleForm.styleLogo"
              style="width:300px;height:150px"
              class="avatar"
            />
            <el-button style="margin-left:20px" type="primary" plain size="small" v-else>上传</el-button>
            <span
              slot="tip"
              class="el-upload__tip"
              style="display: inline-block;color: chocolate;margin: 0 10px;"
            >*340px*150px</span>
          </el-upload>
        </el-form-item>
      </el-col>
    </el-form>
    <!-- <div>
      <div style="display: flex; align-items: center"></div>
      <div style="display: flex; align-items: center;margin-top:20px;">
        <span style="width:120px;">企业Logo:</span>
        <el-upload
          class="avatar-uploader"
          :action="action"
          :show-file-list="false"
          :on-success="successFileOne"
          accept=".png, .jpg, jpeg, JPG, JPEG"
          :before-upload="beforeAvatarUpload"
        >
          <img v-if="styleLogo" :src="styleLogo" style="width:300px;height:150px" class="avatar" />
          <el-button style="margin-left:20px" type="primary" plain size="small" v-else>上传</el-button>
          <span
            slot="tip"
            class="el-upload__tip"
            style="display: inline-block;color: chocolate;margin: 0 10px;"
          >*340px*150px</span>
        </el-upload>
      </div>
    </div>-->
    <el-button type="primary" style="float: right;" @click="onSubmit('ruleForm')">保存</el-button>
  </div>
</template>
<script>
import { api } from '../../api/base/index';
// import baseUrl from '../../config/config';
import { generatePath } from '../../api/base/index';
export const customerEdit = api()('enterprise.config.edit.json');
export default {
  props: {
    code: String,
    userInfo: Object
  },
  data () {
    return {
      fileList: [],
      ruleForm: {
        stylePhone: '',
        styleAddress: '',
        styleBackImg: '',
        styleLogo: ''
      },
      rules: {
        stylePhone: [
          { required: true, message: '请输入公司电话', trigger: 'blur' },
        ],
        styleAddress: [{ required: true, message: '请输入公司地址', trigger: 'blur' }],
      },
      action: '',
      uploadData: {
        OutTradeNo: '123456',
        PhotoType: '01'
      }
    }
  },
  mounted () {
    this.action = generatePath('enterprise.upload.json')

    Object.keys(this.ruleForm).forEach(it => {
      this.ruleForm[it] = this.userInfo[it]

    })
    console.log(this.code)
  },
  methods: {
    beforeAvatarUpload (file) {
      console.log(file);
    },
    successFile (res, fileList) {
      console.log(fileList);
      this.ruleForm.styleBackImg = res.data;
      console.log(this.ruleForm.styleBackImg);
      this.filePath = res.data;
    },
    successFileOne (res, fileList) {
      console.log(fileList);
      this.ruleForm.styleLogo = res.data;
      console.log(this.ruleForm.styleLogo);
      this.filePath = res.data;
    },
    onSubmit () {
      console.log(this.ruleForm)
      customerEdit({
        ...this.ruleForm,
        code: this.code
      }
      ).then(() => {
        this.$message({
          message: '保存成功',
          type: 'success'
        });
      });

    }
  },
}
</script>
<style lang="scss">
</style>